.dropzone-container {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.dropzone-container:hover {
  border-color: #68BA6B;
}

.dropzone-container.error {
  border-color: #ff0000;
}

.image-upload-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title_input {
  font-weight: bold;
  margin-top: 8px;
}

.desc {
  margin-top: 8px;
  color: #ababab;
  font-size: 12px;
}
